import * as React from 'react';
const updateInfo = require('../../static/assets/updated.json');

/**
 * Footer component which shows the last update of the website
 */

const Footer: React.FunctionComponent<{}> = () => {
    return <footer className="page-footer d-flex flex-column text-center text-muted justify-content-center p-2">
        <div>By <a href="/">Joey Albano</a>. Last updated: {new Date(updateInfo.lastUpdated).toLocaleDateString()} (v{updateInfo.version})</div>
    </footer>
}
export default Footer;
