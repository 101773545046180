import { faReadme } from '@fortawesome/free-brands-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
export default class Header extends React.Component<{}, {}> {

  public render() {
    return (
      <div className="bg-primary w-100 text-center">
        <div className="container">
          <div className="row">
            <div className="col"> <a href="/"><FontAwesomeIcon className={`mobile-icon  text-white`} title="home" icon={faHome}></FontAwesomeIcon></a></div>
            <div className="col"> <a href="/about"><FontAwesomeIcon className={`mobile-icon  text-white`} title="about" icon={faReadme}></FontAwesomeIcon></a></div>
          </div>
        </div>
      </div>
    );
  }
}
