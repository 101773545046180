import { graphql } from 'gatsby';
import * as React from 'react';
import Footer from './footer';
import Header from './header';



export default class Layout extends React.Component<{}, {}> {

    public render() {


        return (
            <div className="container-fluid p-0 mb-50">
                <Header></Header>
                <div className="p-2 pb-50">
                    {this.props.children}
                </div>
                <Footer></Footer>
            </div>
        );
    }
}
